<template>
  <div>
    <!--begin::customer-->
    <h3 class="card-label">
      {{ $t('credits.credits') }}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>
    <b-alert v-if="is_confirmed" :show="true" variant="warning">
      <h5>{{ $t('this_invoice_cant_be_editing') }}</h5>
    </b-alert>
    <b-tabs content-class="mt-3" class="nav-custom-link">
      <b-tab :title="$t('credits.basic_information')" @click="toggleTab('basic_information')">

        <div class="row">
          <div class="col-md-6">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('credits.customer') }}<span class="text-danger">*</span></label>
                  <div class="form-group row mb-0">
                    <div class="col-sm-8">
                      <multiselect
                          id="customer_id"
                          v-model="customer"
                          :placeholder="$t('credits.customer')"
                          label="fullname"
                          track-by="id"
                          :options="customers"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getCustomers($event)">
                      </multiselect>
                      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('credits.customer') }}.</span>
                    </div>
                    <div class="col-sm-4" v-if="!isDisabled">
                      <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                    </div>
                  </div>
                  <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.customer_id[0] }}
                                    </span>
                </div>
              </div>
            </div>

            <b-card no-body v-if="customer" class="mt-5">
              <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-50 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                  {{ $t('customer_data_details') }}
                </b-button>
              </b-card-header>

              <b-collapse id="accordion-50" accordion="my-accordion-1" role="tabpanel">
                <b-card-body class="row">
                  <div class="col-lg-12 mb-5">
                    <p><b>{{ $t('name') }}: </b> {{ customer.fullname }}</p>
                    <p><b>{{ $t('mobile') }}: </b> {{ customer.mobile }}</p>
                    <p><b>{{ $t('email') }}: </b> {{ customer.email }}</p>
                    <p><b>{{ $t('tax_no') }}: </b> {{ customer.tax_register }}</p>
                  </div>
                  <div class="col-md-12 mb-5">
                    <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                      <h6 class="my-auto text-white">{{ $t('customers.balances') }}</h6>
                    </div>
                    <table class="table table-row-bordered">
                      <thead>
                      <tr>
                        <th>{{ $t('customers.balance') }}</th>
                        <th>{{ $t('customers.debit') }}</th>
                        <th>{{ $t('customers.currency') }}</th>
                        <th>{{ $t('customers.last_validated_date') }}</th>
                        <th></th>
                      </tr>

                      </thead>
                      <tbody>
                      <template v-if="customer.balances && customer.balances.length > 0">
                        <tr v-for="(row, index) in customer.balances" :key="index">
                          <td>{{ row.credit }}</td>
                          <td>{{ row.debit }}</td>
                          <td>{{ row.currency_name }}</td>
                          <td>{{ row.last_validated_date }}</td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="4">{{ $t('no_balances') }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mt-5">
              <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                  {{ $t('accounts') }}
                </b-button>
              </b-card-header>

              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body class="row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('accounts') }}</label>
                    <div class="input-group">
                      <multiselect
                          v-model="account"
                          :placeholder="$t('accounts')"
                          label="label"
                          track-by="id"
                          :options="accounts"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getAccounts($event)">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/accounting/chart-accounts" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('accounts') }}.</span>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div class="col-md-6">
            <div class="card card-custom">
              <div class="card-body row">

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('credits.credit_date') }}</label>
                  <input type="date" v-model="data.credit_date" disabled class="form-control" :class="validation && validation.credit_date ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.credit_date" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.credit_date[0] }}
                                    </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('credits.issue_date') }}</label>
                  <input type="date" v-model="data.credit_issue" disabled class="form-control" :class="validation && validation.credit_issue ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.credit_issue" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.credit_issue[0] }}
                                    </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('credits.currency') }}</label>
                  <div class="input-group">
                    <select name="" id="currency_id" :disabled="isDisabled" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                      <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.currency_id[0] }}
                                    </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('credits.inventory') }}<span class="text-danger">*</span></label>
                  <div class="input-group">
                    <multiselect v-model="inventory"
                                 :placeholder="$t('open_stock_items.inventory')"
                                 label="name"
                                 track-by="id"
                                 :options="inventories"
                                 :multiple="false"
                                 :disabled="isDisabled"
                                 :class="validation && validation.inventory_id ? 'is-invalid' : ''"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/inventories" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.inventory_id[0] }}
                                    </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('status') }}</label>
                  <select name="" id="f_status" disabled v-model="data.status" type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                    <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                  </select>
                  <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
                </div>

                <div class="col-lg-6 mb-5">
                  <label>{{ $t('is_apply_taxes_invoice') }}:</label>
                  <b-form-checkbox size="lg" :disabled="true" v-model="data.is_apply_taxes_invoice" name="check-button" switch></b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row mt-5">
          <div class="col-md-12">
            <hr>
          </div>
          <div class="col-md-12">
            <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
              <h6 class="mt-2">{{ $t('credits.items') }}</h6>
              <div>
<!--                <button type="button" class="btn btn-primary mr-1 ml-1" @click="addItemRowToList(false)">{{ $t('add_more') }}</button>-->
                <button type="button" class="btn btn-primary" @click="addItemRowToList(true)">{{ $t('fetch_all_items') }}</button>
              </div>
<!--              <button type="button" class="btn btn-primary"  v-if="!isDisabled" @click="addItemRowToList">{{ $t('add_more') }}</button>-->
            </div>
            <div class="table-responsive bg-white pb-5">
              <!--                    <table class="table table-row-bordered w-max-content" @keyup.enter="addItemRowToList" @keyup.46="removeItemRowFromList">-->
              <table class="table table-row-bordered table-custom-padding">
                <thead>
                <tr>
                  <th width="250px">{{ $t('items.item_details') }}</th>
                  <th width="300px">
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('credits.item') }}</span>
                      <a href="javascript:;" v-if="!isDisabled" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                        <b-icon icon="plus"></b-icon>
                      </a>
                    </div>
                  </th>
                  <!--                        <th>{{ $t('credits.description') }}</th>-->
                  <th width="140px">{{ $t('credits.unit_price') }}</th>
                  <th width="140px">{{ $t('credits.original_qty') }}</th>
                  <th width="140px">{{ $t('credits.unit') }}</th>
                  <th width="140px">{{ $t('credits.qty') }}</th>
                  <th width="140px">{{ $t('credits.tax') }}</th>
                  <th width="200px">{{ $t('discount') }}</th>
                  <!--                        <th width="140px">{{ $t('credits.discount_type') }}</th>-->
                  <!--                        <th width="140px">{{ $t('credits.discount_value') }}</th>-->
                  <th width="80px">{{ $t('credits.amount_tax') }}</th>
                  <!--                        <th width="80px">{{ $t('credits.subtotal_before_tax') }}</th>-->
                  <!--                        <th width="80px">{{ $t('credits.subtotal') }}</th>-->
                  <th width="80px">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                  <th width="80px">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                  <th width="10px"></th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="(row, index) in items_list" :key="index" :class="getClassValidationRepeaterByIndex(index)">
                  <td>
                    <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                      <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                      <div class="pl-2 pr-2 text-dark">
                        <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                        <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                        <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                        <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                        <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.sum_available_qty }}</p>
                      </div>
                    </a>
                  </td>
                  <td>
                    <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('credits.item')">
                      <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                        <option value="name" selected>{{ $t('items.name') }}</option>
                        <option value="sku_code">{{ $t('items.sku_code') }}</option>
                      </select>
<!--                      <multiselect v-model="row.item"-->
<!--                                   :placeholder="$t('credits.item')"-->
<!--                                   :label="row.f_by? row.f_by:'name'"-->
<!--                                   track-by="id"-->
<!--                                   :options="items"-->
<!--                                   :multiple="false"-->
<!--                                   @input="selectItem(index)"-->
<!--                                   :taggable="false"-->
<!--                                   :show-labels="false"-->
<!--                                   :show-no-options="false"-->
<!--                                   :show-no-results="false"-->
<!--                                   :internal-search="false"-->
<!--                                   @search-change="getItems(row.f_by, $event)">-->
<!--                      </multiselect>-->
                      <multiselect v-model="row.item"
                                   :placeholder="$t('credits.item')"
                                   :label="row.f_by? row.f_by:'name'"
                                   track-by="id"
                                   disabled
                                   :options="items"
                                   :multiple="false"
                                   @input="selectItem(index)"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                    </div>
                    <input v-model="row.description" type="text" :placeholder="$t('credits.description')" class="form-control mt-3">
                  </td>
                  <td><input v-model="row.unit_price" disabled @input="setValue(index)" type="number" class="form-control"></td>
<!--                  <td><input v-model="row.original_qty" disabled type="number" class="form-control"></td>-->
                  <td><span style="display: block;padding: 10px;text-align: center;">{{row.original_qty}} / {{row.remaining_qty}}</span></td>
                  <td>
                    <multiselect v-model="row.unit" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('credits.unit')"
                                 :placeholder="$t('credits.unit')"
                                 label="name"
                                 track-by="id"
                                 :options="(row.item && row.item.units) ? row.item.units:[]"
                                 :disabled="true"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation[`items_list.${index}.unit`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.unit`][0] }}
                          </span>

                    <input v-model="row.units_number" @input="setValue(index)" v-if="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" min="0" type="number" class="form-control mt-3">

                  </td>
                  <td>
                    <input v-model="row.qty" @input="setValue(index)" :disabled="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" type="number" class="form-control">
                    <span v-if="validation && validation[`items_list.${index}.qty`]" class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`items_list.${index}.qty`][0] }}
                      </span>
                  </td>
<!--                  <td><input v-model="row.tax_percentage" min="0" :disabled="!(row.item && row.item.taxes  && row.item.taxes.length > 0)" @input="setValue(index)" type="number" class="form-control"></td>-->
                  <td>
                    <multiselect v-model="row.tax_applied"
                                 :placeholder="$t('sales_invoices.tax')"
                                 label="name"
                                 track-by="id"
                                 :options="[]"
                                 :multiple="true"
                                 disabled
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
<!--                    <input v-model="row.tax_percentage" min="0" :disabled="true" @input="setValue(index)" type="number" class="form-control">-->
                  </td>

                  <!--                        <td>-->
                  <!--                          <multiselect v-model="row.tax"-->
                  <!--                                       :placeholder="$t('credits.tax')"-->
                  <!--                                       label="name"-->
                  <!--                                       track-by="id"-->
                  <!--                                       :options="taxes"-->
                  <!--                                       :multiple="false"-->
                  <!--                                       :taggable="false"-->
                  <!--                                       :show-labels="false"-->
                  <!--                                       :show-no-options="false"-->
                  <!--                                       :show-no-results="false"-->
                  <!--                                       @search-change="getTaxes($event)">-->
                  <!--                          </multiselect>-->
                  <!--                          <span class="form-text text-muted" style="text-align:end">-->
                  <!--                                                    {{ $t('type_at_least_three_letters') + ' ' + $t('credits.tax') }}.-->
                  <!--                                                </span>-->
                  <!--                        </td>-->
                  <td>
                    <div class="d-flex">
                      <select name="" id="discount_type" @change="setValue(index)" v-model="row.discount_type" class="custom-select">
                        <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <input v-model="row.discount_value" @input="setValue(index)" type="number" class="form-control">
                    </div>
                  </td>
                  <td>{{ row.amount_tax }}</td>
                  <!--                        <td>{{ row.subtotal_before_tax }}</td>-->
                  <!--                        <td>{{ row.subtotal }}</td>-->
                  <td>{{ row.subtotal_before_discount }}</td>
                  <td>{{ row.subtotal_after_discount }}</td>
                  <td>
                    <v-icon style="color: #dc3545;" class="m-40-4" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <!--                      <tr>-->
                <!--                        <td class="border-0" colspan="7"></td>-->
                <!--                        <th colspan="2">{{ $t('credits.credit_subtotal') }}</th>-->
                <!--                        <th colspan="2"><span>{{ data.credit_subtotal ? data.credit_subtotal : '0' }} {{currency_code}}</span></th>-->
                <!--                        <th></th>-->
                <!--                      </tr>-->
                <tr>
                  <td colspan="6"></td>
                  <th colspan="3">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                  <th colspan="2"><span>{{ data.total_amount_before_discount ? data.total_amount_before_discount : '0' }} {{ currency_code }}</span></th>
                </tr>
                <tr>
                  <td class="border-0" colspan="6"></td>
                  <th colspan="3">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                  <th colspan="2"><span>{{ data.total_amount_after_discount ? data.total_amount_after_discount : '0' }} {{ currency_code }}</span></th>
                </tr>
                <tr>
                  <td class="border-0" colspan="6"></td>
                  <th colspan="3">{{ $t('sales_invoices.total_discount') }}</th>
                  <th colspan="2"><span>{{ data.credit_discount ? data.credit_discount : '0' }} {{ currency_code }}</span></th>
                </tr>
                <tr>
                  <td class="border-0" colspan="6"></td>
                  <th colspan="3">{{ $t('sales_invoices.total_after_shipping') }}</th>
                  <th colspan="2"><span>{{ data.credit_shipping ? data.credit_shipping : '0' }} {{ currency_code }}</span></th>
                </tr>
                <tr>
                  <td class="border-0" colspan="6"></td>
                  <th colspan="3">{{ $t('credits.total_tax') }}</th>
                  <th colspan="2"><span>{{ data.credit_taxes ? data.credit_taxes : '0' }} {{ currency_code }}</span></th>
                </tr>
                <tr>
                  <td class="border-0" colspan="6"></td>
                  <th colspan="3">{{ $t('sales_invoices.final_total') }} <i class="fa fa-exclamation-circle ml-2 mr-2" v-b-tooltip.hover.leftbottom :title="$t('sales_invoices.total_amount_before_discount') + ' - ' + $t('sales_invoices.total_discount') + ' + ' + $t('sales_invoices.total_after_shipping') + ' + ' + $t('sales_invoices.total_tax')"></i></th>
                  <th colspan="2"><span>{{ data.credit_total ? data.credit_total : '0' }} {{ currency_code }}</span></th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <b-tabs content-class="mt-3" class="nav-custom-link">
            <b-tab :title="$t('discount_and_settlement')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('credits.discount_type') }}</label>
                    <select name="" id="discount_types" v-model="data.discount_type" class="custom-select" :class="validation && validation.discount_type ? 'is-invalid' : ''">
                      <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <span v-if="validation && validation.discount_type" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.discount_type[0] }}
                                </span>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('credits.discount_value') }}</label>
                    <input type="number" v-model="data.discount_value" class="form-control" :class="validation && validation.discount_value ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.discount_value" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.discount_value[0] }}
                                </span>
                  </div>

                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('credits.adjustment') }}</label>
                    <input type="number" v-model="data.adjustment" class="form-control" :class="validation && validation.adjustment ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.adjustment" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.adjustment[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('delivery_information')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('credits.shipping_details') }}</label>
                    <select name="" id="shipping_details" v-model="data.shipping_details" class="custom-select" :class="validation && validation.shipping_details ? 'is-invalid' : ''">
                      <option v-for="row in shipping_details_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.shipping_details" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_details[0] }}
                            </span>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('credits.shipping_fees') }}</label>
                    <input type="number" @input="calcItemListForTotal" v-model="data.shipping_fees" class="form-control" :class="validation && validation.shipping_fees ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.shipping_fees" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_fees[0] }}
                            </span>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('deposit')">
              <div class="card card-custom">
                <div class="card-body row">
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('notes')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('credits.notes') }}</label>
                    <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                    <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>


        </div>

      </b-tab>
      <b-tab :title="$t('credits.attachments')" :disabled="!idEditing" @click="toggleTab('attachments')">
        <div class="card card-custom mt-5">
          <div class="card-body">
            <attachments :sales-credit-id="idEditing"></attachments>
          </div>
        </div>
      </b-tab>
      <b-tab v-if="idEditing" :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">
        <div class="card card-custom mt-5">
          <div class="card-body">
            <br>
            <activity-log :inner-key="key" :id="idEditing"></activity-log>
          </div>
        </div>
      </b-tab>
    </b-tabs>


    <div class="pl-0 pr-0" v-if="tab_name != 'activity_log'">
      <div class="row">
        <div v-if="!is_confirmed" class="col-lg-6 mt-10">
          <button type="reset" class="btn btn-primary mr-2" @click="save($event)">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('customers.add_new_customer')">
      <customer-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewCustomer"></customer-form>
    </b-modal>
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Attachments from "./Attachments";
import ItemForm from "@/view/content/forms/ItemForm";
import CustomerForm from "@/view/content/forms/CustomerForm";
import ActivityLog from "@/view/content/widgets/details/ActivityLog";
import {mapGetters, mapState} from "vuex";
import dataTypeNumbers from '@/core/config/mix/dataTypeNumbers';

export default {
  name: "form-credits",
  components: {'attachments': Attachments, 'item-form': ItemForm, 'customer-form': CustomerForm, 'activity-log': ActivityLog},
  data() {
    return {
      mainRoute: 'sales/credits',
      mainRouteDependency: 'base/dependency',
      mainTaxSettingsRoute: 'sales/tax_setting_sales',
      key: 'SalesCredit',

      mainRouteSalesInvoice: 'sales/sales_invoices',

      data: {
        credit_code: null,
        credit_subtotal: 0,
        credit_total: 0,
        credit_discount: 0,
        credit_shipping: 0,
        credit_taxes: 0,
        total_amount_before_discount: 0,
        total_amount_after_discount: 0,
        shipping_details: null,
        shipping_fees: null,
        adjustment: null,
        customer_id: null,
        discount_type: 1,
        discount_value: null,
        currency_id: null,
        status: 1,
        credit_date: null,
        notes: null,
        // branch_id: null,
        // exchange_rate: null,
        // deposite_value: null,
        // payment_terms: null,
        credit_issue: null,
        inventory_id: null,
        account_id: null,
        is_general: 0,
        sale_invoice_id: this.$route.params.sale_invoice_id ? this.$route.params.sale_invoice_id : null,
        is_apply_taxes_invoice: true,
        total_out_tax: 0,
        total_tax_type_val: [],
        session_id: null,
      },
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      inventory: null,

      accounts: [],
      account: null,
      customers: [],
      currencies: [],
      items: [],
      taxes: [],
      units: [],
      inventories: [],
      // branches: [],
      // branch: null,
      shipping_details_list: [],
      status_list: [],
      isDisabled: false,
      // shipping_details_list: [
      // {id: 0, name: this.$t('shipping_details.auto')},
      // {id: 1, name: this.$t('shipping_details.dont_show')},
      // {id: 2, name: this.$t('shipping_details.show_main_client_details')},
      // {id: 3, name: this.$t('shipping_details.show_secondary_client_details')},
      // ],
      tax_default: null,
      unit_default: null,
      validation: [],
      customer: null,
      items_list_form: {id: null, item: null, description: null, unit_price: null, original_qty: null, remaining_qty: null, qty: null, unit: null, tax: null, discount_type: null, discount_value: null, subtotal: null, amount_tax: null, subtotal_before_tax: null, tax_percentage: null, subtotal_before_discount: null, subtotal_after_discount: null, tax_applied: [], subtotal_after_tax: null, amount_excluded_tax: null, units_number: null},
      items_list: [],
      items_list_hide: [],
      discount_types: [
        {id: 1, name: '-'},
        {id: 2, name: '%'},
        {id: 3, name: this.$t('discount_types.value')},
      ],
      tab_name: 'basic_information',
      currency_code: null,
      is_company_has_tax_no: false,
      is_confirmed: false,
      repeater_validation: [],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {
    account: function (val) {
      if (val) {
        this.data.account_id = val.id;
      }
    },
    // items_list: {
    //   handler(val) {
    //     if (val.length > 0) {
    //       this.calcItemListForTotal();
    //     }
    //   },
    //   deep: true
    // },
    '$route'(val) {
      if (val.params.id) {
        this.idEditing = val.params.id;
        this.getData();
      }
    },
    customer: function (val) {
      if (val) {
        this.data.customer_id = val.id;
      } else {
        this.data.customer_id = null;
      }
    },
    'data.customer_id': function (val) {
      if (val) {
        let _cu = this.customers.filter(row => row.id == val);
        if (_cu[0]) {
          _cu = _cu[0]
          if (this.isDisabled == false) {
            // this.data.currency_id = _cu.currency_id;
            if (_cu.currency_id) {
              this.data.currency_id = _cu.currency_id;
            } else {
              this.getDefaultCurrency();
            }
          }
        }
      }
    },
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      } else {
        this.data.inventory_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      }
    },
    "data.currency_id": function (val) {
      if (val) {
        let _currency = this.currencies.find((row) => row.id == val);
        if (_currency) {
          this.currency_code = _currency.code;
        } else {
          this.currency_code = null;
        }
      } else {
        this.currency_code = null;
      }

      // if (val) {
      //   this.getExchangeRate(val);
      // } else {
      //   this.data.exchange_rate = null;
      // }
    },
    "data.shipping_fees": function (val) {
      if (val) {
        if(String(val).length > 12){
          this.data.shipping_fees = String(val).slice(0, 12);
        }
      }
    },
  },
  methods: {
    ...dataTypeNumbers,

    validationQTYChecking(_status = true) {
      let _main_validation_status = false;
      this.validation = [];
      this.items_list.forEach((row, index) => {
        let _validation_status = false;

        if (parseFloat(row.qty) > parseFloat(row.remaining_qty)) {
          this.validation[`items_list.${index}.qty`] = [];
          this.validation[`items_list.${index}.qty`][0] = this.$t('the_quantity_field_must_be_less_or_equal_remaining_quantity');
          _validation_status = true;
        }

        if(!row.unit && _status){
          this.validation[`items_list.${index}.unit`] = [];
          this.validation[`items_list.${index}.unit`][0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }

        // if (parseFloat(row.amount_tax) <= 0 && row.tax_applied.length > 0) {
        //   _validation_status = true;
        // }else if(!row.amount_tax && _status && row.tax_applied.length > 0){
        //   _validation_status = true;
        // }
        //
        // if (parseFloat(row.subtotal_before_discount) <= 0) {
        //   _validation_status = true;
        // }else if(!row.subtotal_before_discount && _status){
        //   _validation_status = true;
        // }


        if (_validation_status && _status){
          let _index = this.repeater_validation.indexOf(index);
          if (_index < 0) {
            this.repeater_validation.push(index);
          }
        }else{
          let _index = this.repeater_validation.indexOf(index);
          if (_index > -1) {
            this.repeater_validation.splice(_index, 1);
          }
        }

      });
      if (this.repeater_validation.length > 0){
        _main_validation_status = true;
      }
      if (_main_validation_status){
        let _items = [...this.items_list];
        this.items_list = [];
        this.items_list = _items;
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }
      return _main_validation_status;

    },

    save(event) {
      if (this.validationQTYChecking()) {
        event.preventDefault();
        return false;
      } else {
        if (this.isEditing) {
          this.update();
        } else {
          this.create();
        }
      }
    },

    create() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => parseFloat(row.qty) > 0);

      ApiService.post(`${this.mainRoute}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = [];
            this.$successAlert(response.data.message);
            // this.$router.push({name: 'credits.edit', params: {id: response.data.data.id}});
            this.$router.push({name: 'credits.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => parseFloat(row.qty) > 0);

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = [];
            this.$router.push({name: 'credits.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
            // return 'tr-validation-error';
          return 'border-validation';
      }
      return '';
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.credit_code = response.data.data.credit_code;
        this.data.sale_invoice_id = response.data.data.sale_invoice_id;
        this.data.credit_subtotal = response.data.data.credit_subtotal;
        this.data.credit_total = response.data.data.credit_total;
        this.data.credit_discount = response.data.data.credit_discount;
        this.data.credit_shipping = response.data.data.credit_shipping;
        this.data.credit_taxes = response.data.data.credit_taxes;
        this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
        this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;

        this.data.total_out_tax = response.data.data.total_out_tax ? response.data.data.total_out_tax : 0;
        this.data.total_tax_type_val = response.data.data.total_tax_type_val ? response.data.data.total_tax_type_val : [];

        this.data.shipping_details = response.data.data.shipping_details;
        this.data.shipping_fees = response.data.data.shipping_fees;
        this.data.adjustment = response.data.data.adjustment;
        this.data.customer_id = response.data.data.customer_id;
        this.data.discount_type = response.data.data.discount_type;
        this.data.discount_value = response.data.data.discount_value;
        this.data.currency_id = response.data.data.currency_id;
        this.data.status = response.data.data.status;
        this.data.credit_date = response.data.data.credit_date;
        this.data.credit_issue = response.data.data.credit_issue;
        this.data.notes = response.data.data.notes;
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.is_general = response.data.data.is_general;
        this.data.session_id = response.data.data.session_id ? response.data.data.session_id : null;
        this.inventory = response.data.data.inventory;
        this.customer = response.data.data.customer;
        // this.data.exchange_rate = response.data.data.exchange_rate;
        this.data.account_id = response.data.data.account_id;
        this.account = response.data.data.account;
        this.data.is_apply_taxes_invoice = response.data.data.is_apply_taxes_invoice;

        this.is_confirmed = response.data.data.status == 2 ? true : false;

        // this.data.branch_id = response.data.data.branch_id;
        // this.branch = response.data.data.branch;
        if (!response.data.data.account_id) {
          this.getDefaultAccount();
        }

        this.items_list = response.data.data.items_list.map((row)=>{
          row.remaining_qty = (parseFloat(row.original_qty) -  parseFloat(row.return_qty));
          return row;
        });

        if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
          this.addItemRowToList();
        }
      });
    },
    async getSalesInvoiceData() {
      if (this.data.sale_invoice_id) {
        await ApiService.get(`${this.mainRouteSalesInvoice}/${this.data.sale_invoice_id}`).then((response) => {

          // this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
          // this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;
          //
          // this.data.credit_subtotal = response.data.data.credit_subtotal;
          // this.data.credit_total = response.data.data.invoice_total - response.data.data.invoice_total_shipping;
          // this.data.credit_total = this.data.credit_total ? (this.data.credit_total).toFixed(2) : 0;
          //
          // this.data.credit_taxes = response.data.data.invoice_total_taxes;


          this.data.customer_id = response.data.data.customer_id;
          this.data.currency_id = response.data.data.currency_id;
          this.data.inventory_id = response.data.data.inventory_id;
          this.data.session_id = response.data.data.session_id ? response.data.data.session_id : null;
          this.inventory = response.data.data.inventory;
          this.customer = response.data.data.customer;

          this.items = response.data.data.items_list.map((row) => {
            return row.item;
          });
          this.items_list_hide = response.data.data.items_list.map((row) => {
            row.original_qty = row.qty;
            row.remaining_qty = (parseFloat(row.qty) -  parseFloat(row.return_qty));

            row.discount_type = 1;
            row.discount_value = 0;
            // row.subtotal_before_discount = row.subtotal_after_discount;
            row.subtotal_before_discount = 0;
            row.unit_price = row.subtotal_after_discount ? (parseFloat(row.subtotal_after_discount) / row.qty).toFixed(2)  : 0;

            row.subtotal_after_discount = 0;
            row.amount_tax = 0;
            row.subtotal_after_tax = 0;
            row.amount_excluded_tax = 0;
            row.data_validation = [];
            row.units_number = 0;

            return row;
          });

          if (this.data.sale_invoice_id && response.data.data.customer_id && response.data.data.inventory_id) {
            this.isDisabled = true;
          }
          this.calcItemListForTotal();
        });
      }
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    async getCustomers(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes?type=4`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },
    getUnits() {
        ApiService.get(`${this.mainRouteDependency}/units`).then((response) => {
          this.units = response.data.data;
        });

    },

    // async getDefaultTax() {
    //   await ApiService.get(`${this.mainRouteDependency}/default-tax`).then((response) => {
    //     this.tax_default = response.data.data;
    //   });
    // },


    getShippingDetails() {
      ApiService.get(this.mainRouteDependency + "/shipping_details").then((response) => {
        this.shipping_details_list = response.data.data;
      });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status", {params: {type: 'credits'}}).then((response) => {
        this.status_list = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;

      });
    },
    // getBranches() {
    //   ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
    //     this.branches = response.data.data;
    //   });
    // },

    addItemRowToList(is_fetch_all) {
      // this.items_list_form.tax = this.tax_default;
      // this.items_list_form.unit = this.unit_default;
      // this.items_list.unshift(this.items_list_form);
      // this.items_list_form = {id: null, item: null, description: null, unit_price: null, original_qty: null, qty: null, tax: null, discount_type: null, discount_value: null, subtotal: null, amount_tax: null, subtotal_before_tax: null, unit: this.unit_default};
      if (is_fetch_all){
        let _items = this.items_list_hide.map((row)=>{
          row.qty = 0;
          return {...row};
        });
        this.items_list = [..._items];
        this.calcItemListForTotal();
      }else {
        this.items_list.unshift(this.items_list_form);
        this.items_list_form = {id: null, item: null, unit_price: null, original_qty: null, remaining_qty: null, qty: null, unit: null, tax_1: this.user_personal_info.tax, tax_2: null, discount_type: null, discount_value: null, subtotal: null, amount_tax: null, subtotal_before_tax: null, tax_percentage: null, subtotal_before_discount: null, subtotal_after_discount: null, tax_applied: [], subtotal_after_tax: null, amount_excluded_tax: null, units_number: null};

      }

    },
    removeItemRowFromList(index = 0) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
      this.calcItemListForTotal();
    },

    selectItem(index) {
      // this.items_list[index].unit_price = this.items_list[index].item ? this.items_list[index].item.purchase_price : 0;
      // this.items_list[index].tax_percentage = this.items_list[index].item ? this.items_list[index].item.total_taxes : 0;
      // this.setValue(index);
      let _selected = this.items_list_hide.find((row)=>{
        if (row.item && this.items_list[index] && this.items_list[index].item && row.item.id == this.items_list[index].item.id){
          return row;
        }
      });
      if (_selected){
        _selected.qty = 0;
        this.items_list[index] = {..._selected};
        this.setValue(index);
      }

    },
    // setValue(index) {
    //   this.items_list[index].subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);
    // },
    setValue(index) {
      this.zeroForNegative();

      if (this.items_list[index].item && this.items_list[index].unit && this.items_list[index].item.unit) {
        if (this.items_list[index].item.unit.id != this.items_list[index].unit.id) {
          if (this.items_list[index].item.is_min) {
            this.items_list[index].qty = ((this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 1) * (this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 1)).toFixed(3);
          } else {
            this.items_list[index].qty = ((this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 1) / (this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 1)).toFixed(3);
          }
        } else {
          this.items_list[index].units_number = 0;
          // this.items_list[index].qty = 0;
        }
      }



      let promise = this.getItemTax(index);
      Promise.all([promise]).then(()=>{
        // this.items_list[index].subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);
        let _subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);
        this.items_list[index].subtotal_before_tax = (_subtotal).toFixed(2);

        let _discount = 0;
        if (this.items_list[index].discount_type == 1 || !this.items_list[index].discount_type) {
          _discount = 0;
        } else {
          _discount = ((this.items_list[index].discount_type == 2 && this.items_list[index].discount_value) ? (parseFloat(this.items_list[index].discount_value) / 100) * _subtotal : (this.items_list[index].discount_value ? parseFloat(this.items_list[index].discount_value) : 0));
        }

        /**
         *
         * calc data subtotal before discount  = unit price * quantity
         * calc data subtotal after discount  = (unit price * quantity) - discount
         */
        this.items_list[index].subtotal_before_discount = _subtotal;
        this.items_list[index].subtotal_after_discount = _subtotal - _discount;

        this.items_list[index].subtotal_after_tax = (_subtotal + parseFloat(this.items_list[index].amount_excluded_tax)).toFixed(2);
        // _subtotal = _subtotal - _discount;
        // let _tax = _subtotal * (this.items_list[index].tax_percentage ? parseFloat(this.items_list[index].tax_percentage) / 100 : 0);

        /**
         * used to get tax
         * this is function dependent on id for item and quantity
         */
        // this.getItemTax({qty: this.items_list[index].qty, item_id: this.items_list[index].item.id}, index);

        // this.items_list[index].subtotal_before_tax = (_subtotal).toFixed(2);
        // this.items_list[index].amount_tax = (_tax).toFixed(2);
        this.items_list[index].subtotal_before_discount = this.items_list[index].subtotal_before_discount ? (this.items_list[index].subtotal_before_discount).toFixed(2): 0;
        this.items_list[index].subtotal_after_discount = this.items_list[index].subtotal_after_discount ? (this.items_list[index].subtotal_after_discount).toFixed(2): 0;
        this.items_list[index].subtotal = parseFloat(this.items_list[index].subtotal_before_tax).toFixed(2);

        // this.items_list[index].subtotal = _subtotal;
        this.calcItemListForTotal();
      })
    },


    zeroForNegative() {
      this.items_list = this.items_list.map((row) => {
        if(String(row.unit_price).length > 12){
          row.unit_price = String(row.unit_price).slice(0, 12);
        }
        if(String(row.qty).length > 9){
          row.qty = String(row.qty).slice(0, 9);
        }

        if (isNaN(row.unit_price) || !Number(row.unit_price)) {
          row.unit_price = 1;
        } else if (parseFloat(row.unit_price) < 1) {
          row.unit_price = 1;
        }

        if (isNaN(row.qty)) {
            row.qty = 0;
        } else if (parseFloat(row.qty) < 0) {
          row.qty = 0;
        }

        // else if (parseFloat(row.qty) > parseFloat(row.remaining_qty)) {
        //   row.qty = row.remaining_qty;
        // }

        if (isNaN(row.units_number)) {
          row.units_number = 0;
        } else if (parseFloat(row.units_number) < 0) {
          row.units_number = 0;
        }

        // if (isNaN(row.discount_value)) {
        //   row.discount_value = 0;
        // }else if (parseFloat(row.discount_value) < 0) {
        //   row.discount_value = 0;
        // }else if (parseFloat(row.discount_value) > parseFloat(row.subtotal_before_discount)) {
        //   row.discount_value = row.subtotal_before_discount;
        // }

        if ((row.discount_type == 1 || !row.discount_type) && (row.discount_value != 0 && row.discount_value != null)) {
          row.discount_type = 2;
        }
        if (row.discount_type == 2) {
          if (parseFloat(row.discount_value) > 100) {
            row.discount_value = 100;
          }
        } else if (row.discount_type == 3) {
          if (parseFloat(row.discount_value) > parseFloat(row.subtotal_before_discount)) {
            row.discount_value = row.subtotal_before_discount;
          }
        }

        if (isNaN(row.tax_percentage) || !Number(row.tax_percentage)) {
          row.tax_percentage = 0;
        }
        if (parseFloat(row.tax_percentage) < 0) {
          row.tax_percentage = 0;
        }
        if (parseFloat(row.tax_percentage) > 100) {
          row.tax_percentage = 100;
        }
        return row;

      });
    },
    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    getDataAfterCreateNewCustomer(object) {
      let promise = this.getCustomers();
      Promise.all([promise]).then(() => {
        this.data.customer_id = object.id;
      })
    },
    getDataAfterCreateNewItem(object) {
      // let promise = this.getItems();
      // Promise.all([promise]).then(() => {
      if (object) {
        this.items_list[0].item = object;
        this.items_list[0].unit_price = object.sale_price;
        this.items_list[0].tax_percentage = object.total_taxes ? object.total_taxes : 0;
        // this.items_list[0].tax = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
      }
      // })
    },
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/7").then((response) => {
        this.data.credit_code = response.data.data.code;
      });
    },

    // getAccounts() {
    //     ApiService.get(this.mainRouteDependency + `/account_parents`,{params:{checkDisabled: 1,key:'sales_credit'}}).then((response) => {
    //         this.accounts = response.data.data;
    //     });
    // },
    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts = response.data.data;
        });
    },
    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/sales_credit`).then((response) => {
        this.account = response.data.data;
      });
    },

    loadOptions() {
    },
    updateValue(value) {
      this.data.account_id = value;
    },

    // getExchangeRate(id) {
    //   ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
    //     this.data.exchange_rate = response.data.data.initial_val;
    //   });
    // },
    calcItemListForTotal() {
      this.data.credit_shipping = this.data.shipping_fees;

      if (this.items_list.length > 0) {

        // this.data.credit_subtotal = this.$_.sumBy(this.items_list, (row) => {
        //   return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
        // });
        /**
         * sum amount before discount
         */
        this.data.total_amount_before_discount = this.$_.sumBy(this.items_list, (row) => {
          return (row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0);
        });

        /**
         * sum amount after discount
         */
        this.data.total_amount_after_discount = this.$_.sumBy(this.items_list, (row) => {
          return (row.subtotal_after_discount ? parseFloat(row.subtotal_after_discount) : 0);
        });


        this.data.credit_subtotal = this.$_.sumBy(this.items_list, (row) => {
          return ((row.unit_price ? row.unit_price : 0) * (row.qty ? row.qty : 0));
        });

        this.data.credit_discount = this.$_.sumBy(this.items_list, (row) => {
          if (!row.discount_type || row.discount_type == 1) {
            return 0;
          }
          // let _subtotal = row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
          let _subtotal = ((row.unit_price ? row.unit_price : 0) * (row.qty ? row.qty : 0));
          return ((row.discount_type == 2 && row.discount_value) ? (parseFloat(row.discount_value) / 100) * _subtotal : (row.discount_value ? parseFloat(row.discount_value) : 0));

        });

        this.data.credit_shipping = (this.data.credit_shipping ? parseFloat(this.data.credit_shipping) : 0);


        this.data.credit_taxes = this.$_.sumBy(this.items_list, (row) => {
          return row.amount_tax ? parseFloat(row.amount_tax) : 0;
        });

        let _amount_excluded_tax = this.$_.sumBy(this.items_list, (row) => {
          return row.amount_excluded_tax ? parseFloat(row.amount_excluded_tax) : 0;
        });

        /**
         * summation tax dependent on  include_tax
         * if be 1 used to sum
         * another not used
         */
        // let _total_tax_included_tax = this.$_.sumBy(this.items_list, (row) => {
        //   let _arr_include_taxes = row.include_taxes;
        //   let _include_tax = null
        //   if (_arr_include_taxes && _arr_include_taxes[0]) {
        //     _include_tax = _arr_include_taxes[0];
        //   }
        //   if (_include_tax && _include_tax.include_tax == 1) {
        //     return row.amount_tax ? parseFloat(row.amount_tax) : 0;
        //   }
        //   return 0;
        // });

        this.data.total_out_tax = this.$_.sumBy(this.items_list, (row) => {
          if(!row.tax_applied || row.tax_applied.length == 0){
            if (row.unit_price && row.qty){
              return parseFloat(row.unit_price) *  parseFloat(row.qty);
            }
          }
          return 0;
        });

        this.setTotalTaxTypeVal();

        this.data.credit_total = (this.data.total_amount_after_discount  + _amount_excluded_tax + this.data.credit_shipping);


        this.data.credit_subtotal = this.data.credit_subtotal ? this.data.credit_subtotal.toFixed(2) : 0;
        this.data.total_out_tax = this.data.total_out_tax ? this.data.total_out_tax.toFixed(2) : 0;
        this.data.credit_discount = this.data.credit_discount ? this.data.credit_discount.toFixed(2) : 0;
        this.data.credit_shipping = this.data.credit_shipping ? this.data.credit_shipping.toFixed(2) : 0;
        this.data.credit_taxes = this.data.credit_taxes ? this.data.credit_taxes.toFixed(2) : 0;
        this.data.credit_total = this.data.credit_total ? this.data.credit_total.toFixed(2) : 0;
        this.data.total_amount_before_discount = this.data.total_amount_before_discount ? this.data.total_amount_before_discount.toFixed(2) : 0;
        this.data.total_amount_after_discount = this.data.total_amount_after_discount ? this.data.total_amount_after_discount.toFixed(2) : 0;
      }
    },
    setTotalTaxTypeVal(){
      this.data.total_tax_type_val = [];
      this.items_list.forEach((row)=>{
        if(row.tax_applied && row.tax_applied.length > 0){
          row.tax_applied.forEach((_row)=>{
            let index_tax = this.data.total_tax_type_val.findIndex(it => _row.tax_id == it.tax_id);
            if (index_tax >= 0){
              this.data.total_tax_type_val[index_tax].tax_value =  (this.data.total_tax_type_val[index_tax].tax_value ? parseFloat(this.data.total_tax_type_val[index_tax].tax_value) : 0)+ (row.tax_value ? parseFloat(row.tax_value) : 0);
              this.data.total_tax_type_val[index_tax].amount_applied =  (this.data.total_tax_type_val[index_tax].amount_applied ? parseFloat(this.data.total_tax_type_val[index_tax].amount_applied) : 0)+ (row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0);
            }else{
              this.data.total_tax_type_val.push({tax_id: _row.tax_id, tax_value: _row.tax_value, amount_applied: row.subtotal_before_tax});
            }
          });

        }
      });
    },
    /**
     * this function used
     * invoice_id and item_id and quantity
     */
    // async getItemTax(data, index) {
    //   let _data = {};
    //   if (this.data.sale_invoice_id) {
    //     _data = {
    //       qty: data.qty,
    //       item_id: data.item_id,
    //       sales_invoice_id: this.data.sale_invoice_id,
    //     };
    //   } else {
    //     _data = {
    //       qty: data.qty,
    //       item_id: data.item_id,
    //       customer_id: this.data.customer_id,
    //     };
    //   }
    //
    //   await ApiService.query(this.mainRouteDependency + "/tax_calculation_sales_credits", {
    //     ..._data,
    //   }).then((response) => {
    //     if (response.data.data.price_per_unit) {
    //       this.items_list[index].unit_price = response.data.data.price_per_unit;
    //     }
    //     this.items_list[index].amount_tax = (response.data.data.tax_value).toFixed(2);
    //     this.items_list[index].discount = (response.data.data.discount).toFixed(2);
    //     this.items_list[index].include_taxes = response.data.data.include_taxes;
    //     this.calcItemListForTotal();
    //   });
    //
    // },
    // getTaxSettings() {
    //   ApiService.get(this.mainTaxSettingsRoute).then((response) => {
    //     this.is_company_has_tax_no = response.data.data.is_company_has_tax_no;
    //     // this.data.is_apply_taxes_invoice = response.data.data.is_apply_taxes_setting;
    //   });
    // },
    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.branch_id = response.data.data.branch_id;
        this.inventory = response.data.data.inventory;
        this.branch = response.data.data.branch;
        // this.unit_default = response.data.data.unit;
        // this.items_list_form.unit = response.data.data.unit;
        this.items_list_form.tax = response.data.data.tax;
        if (this.items_list[0]) {
          // this.items_list[0].unit = response.data.data.unit;
          this.items_list[0].tax = response.data.data.tax;
        }
      });
    },
    async getItemTax(index){
      // if(this.isApplyTaxesInvoice) {
      let item_id = this.items_list[index].item ? this.items_list[index].item.id : null;
      let quantity = this.items_list[index].qty ? parseFloat(this.items_list[index].qty) : null;
      // let unit_price = this.items_list[index].unit_price ? this.items_list[index].unit_price : null;
      let discount = this.items_list[index].discount_value ? this.items_list[index].discount_value : null;
      let discount_type = this.items_list[index].discount_type ? this.items_list[index].discount_type : null;

      if (item_id && quantity )
        await ApiService.get(`/v2/items/sale/${this.data.sale_invoice_id}/credit-note/calculate-subtotal`,
            {
              params: {
                item_id: item_id, quantity: (quantity).toFixed(3),
                // sale_price: unit_price,
                discount: discount, discount_type: discount_type, row_index: index
              }
            }).then((response) => {
          this.items_list[response.data.data.row_index].amount_tax = this.parseToShortFloat(response.data.data.tax_total);
          this.items_list[response.data.data.row_index].tax_applied = response.data.data.applied_taxes;
          this.items_list[response.data.data.row_index].amount_excluded_tax = this.parseToShortFloat(response.data.data.excluded_tax_per_item);
          this.items_list[response.data.data.row_index].unit_price = response.data.data.unit_price ? this.parseToShortFloat(response.data.data.unit_price) : 0;
          // this.items_list[response.data.data.row_index].subtotal_before_discount = (response.data.data.unit_price ?  response.data.data.unit_price : 0) * (this.items_list[response.data.data.row_index].quantity ? parseFloat(this.items_list[response.data.data.row_index].quantity): 0)

          // let _subtotal_before_tax = this.parseToShortFloat(parseFloat(response.data.data.sub_total) - parseFloat(response.data.data.included_taxes_total))
          this.items_list[response.data.data.row_index].data_validation = {item_id: item_id , tax_total: response.data.data.tax_total, subtotal_before_tax: response.data.data.sub_total};

          this.calcItemListForTotal();

        });
      // }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_credits_management"), route: '/sales/credits'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    // this.getTaxSettings();
    this.getCurrencies();
    // this.getTaxes();

    this.getUnits();
    this.getShippingDetails();
    // this.getCustomers();
    this.getStatus();
    this.getInventories();

    // this.getBranches();
    this.getAccounts();
    if (!this.data.account_id) {
      this.getDefaultAccount();
    }


    if (this.idEditing) {
      this.getData();
    } else {
      this.data.credit_date = new Date().toISOString().slice(0, 10);
      this.data.credit_issue = new Date().toISOString().slice(0, 10);
      this.getCode();

      let _promise_1 = this.defaultDataForUser();
      Promise.all([_promise_1]).then(() => {
        if (this.data.sale_invoice_id) {
          let promise = this.getSalesInvoiceData();

          Promise.all([promise]).then(() => {
            if (this.items_list.length <= 0) {
              this.addItemRowToList();
            }
          });
        } else {
          if (this.items_list.length <= 0) {
            this.addItemRowToList();
          }
        }

      });

    }

  },
};
</script>